import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
import GetCTA from "./get-cta"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';
import Modal from 'react-bootstrap/Modal'
import { useLocation, navigate } from "@reach/router";
import { VideoPlayButton } from '../components/icon';
import $ from 'jquery';
// import ScrollAnimation from 'react-animate-on-scroll';

import InvestorGuideForm from "./forms/investors-guide-form";
import DropDown from './Dropdown/dropdown';
import BlogPageSidebar from "./forms/blogpage-sidebar";
import ImageTransform from "./common/ggfx-client/module/components/image-transform";

const LeftTile = (props) => {
  useEffect(() => {
    $(".property_report").click(function (event) {
      var searcValue = $(".report_search_pcode").val();
      navigate("/property-report/?postcode=" + searcValue);
      event.preventDefault();

    })

  }, []);
  let proptitle = '';
  if (props.Title != null && props.Title != "") {
      proptitle = props.Title.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
  const [active, setActive] = useState('+ Read More');
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  
  const [brochureShow, setBrochureShow] = useState(false);
  const handleAccordionClick = (event) => {
    var thishash = '#' + event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 270
    }, 1000);

    if (active === '+ Read More') {
      setActive('- Read Less')
    } else {
      setActive('+ Read More')
    }
  }

  if (props.Content && props.Content.length < 600) {
    var long_desc_first_part = props.Content;
  }
  else {
    var long_desc_first_part = props.Content ? props.Content.split(/\s+/).slice(0, 60).join(" ") + '...' : '';
  }


  const location = useLocation();
  const thePath = location.pathname
  const PageSlug = thePath.substring(thePath.lastIndexOf('/') + 1) + ' '
  if (props.TileVideo != null) {
    var videoid = getVideoId(props.TileVideo);
  }
  const [showVideo, setShowVideo] = React.useState(false);
  const [, setPlay] = React.useState(false);
  const [] = useState(false)
  const [, setMypropertyValue] = useState(0);
  const [] = useState(0);

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Tile_Image_Transforms) {    
        processedImages = props.imagetransforms.Tile_Image_Transforms;
    }
    const image_url = props.TileImg && props.TileImg.internal.description ? props.TileImg.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

    const imagename = (props.appId ? `${props.appId}` : 'articles') + '.Tile_Image.commontile';
   
  return (<React.Fragment>
    <section className="section-area d-md-block section-tile" id={proptitle}>
      <Container className="areaSec ">
        <Row className="align-items-lg-center">
          <Col lg="6" className={`col-xl-7 imgarea ${props.Title.toLowerCase().replace(/\s/g, '')}`}>
            {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}

              <div className="vedio-card playing-video">
                <div className="tile-image-wrapper">
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename={imagename} attr={{ alt: `${PageSlug}${props.Title} - Orlando Reid` }} imagetransformresult={processedImages} id={props.AriticleId}/>
                </div>                   
                {props.TileVideo != null && props.TileVideo != '' &&
                  <button className="btn-play"
                    onClick={() => { setPlay(true); setShowVideo(true) }}
                  >

                    <VideoPlayButton />
                  </button>
                }
              </div>
              {
                showVideo &&

                <YouTube
                  video={videoid.id}
                  autoplay
                  onEnd={() => { setPlay(false); setShowVideo(false) }}
                  modestBranding={1}
                  onPlaying={trackerVideo(props.Title)}

                />

              }
            {/* </ScrollAnimation> */}

          </Col>

          <Col lg="6" className={`col-xl-5 textContarea ${props.Title.toLowerCase().replace(/\s/g, '')}`}>
            {/* <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} > */}

              <div className="property-report our-area">
                <span className="sub-title">{props.Title}</span>
                {long_desc_first_part != null && long_desc_first_part != "" &&
                  <>
                    {active === '- Read Less' ? <ReactMarkdown source={props.Content} allowDangerousHtml /> : <ReactMarkdown source={props.Content.length > 600 ? long_desc_first_part : props.Content} allowDangerousHtml />}
                    {props.Content.length > 600 && !props.CTA_Label && <a href="javascript:;" className="read-more custom-link" onClick={() => handleAccordionClick(proptitle)}>{active}</a>}
                  </>
                }
                {/* {(props.CustomUrl == null || props.CustomUrl == "") && props.ShowForm != true && props.CTAUrl != null &&
                  <GetCTA classname="btn btn-default btn-block" link={props.CTAUrl} label={props.Label} />
                } */}
                {/* {props.CustomUrl != "" && props.CustomUrl != null &&
                  <Link className="btn btn-default btn-block" to={props.CustomUrl}>{props.Label}</Link>
                } */}
                {/* {props.CustomUrl == null && props.ShowForm != true &&
                <GetCTA classname = "btn btn-default btn-block" link = {props.CTAUrl} label={props.Label}/>
                }  */}
                {props.Download_guide_form != true &&
                  <a onClick={() => setBrochureShow(true)} className="btn btn-success">{props.Label}</a>
                }
                {props.Show_Property_Report != null && props.Show_Property_Report != "" && props.Show_Property_Report == true && <BlogPageSidebar Show_Property_Report={props?.Show_Property_Report}/>}
                {props.CTA_Dropdown != null && props.CTA_Dropdown != "" &&
                  <DropDown CTATitle={props.CTA_Label} data={props.CTA_Dropdown} valueChange={setMypropertyValue} />
                }
                <Modal
                  show={brochureShow}
                  centered={true}
                  onHide={() => setBrochureShow(false)}
                  dialogClassName="modal-90w modal-form"
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header closeButton>
                    {/* <Modal.Title id="example-custom-modal-styling-title">
                    Download A Brochure
                    </Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>
                    {/* <Brochure brochure={siteUrl + props.brochure?.publicURL} subject={props?.Title} /> */}
                    <InvestorGuideForm className="popup" propertyName={"Assisted Living Property Investment"} retURL={process.env.GATSBY_SITE_URL+"/thank-you-assisted-living-brochure"}/>
                  </Modal.Body>
                </Modal>
              </div>
            {/* </ScrollAnimation> */}
          </Col>
        </Row>
      </Container>
    </section>

  </React.Fragment>)

}

export default LeftTile;
